import React from 'react'
import { Link } from 'react-router-dom'


const HeaderTopbar = (props) => {
    return (
        <section className="topbar">
            <h2 className="hidden">some</h2>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12 col-12">
                        <div className="contact-intro">
                            <ul>
                                <li>¡Gracias por confirmar! <i className="fi flaticon-heart"></i></li>
                                <li>Los esperamos con mucha emoción, no olviden su pase de abordar.  <i className="fi flaticon-wedding"></i></li>
                                <li>¡Nos vemos pronto! <i className="fi flaticon-dove"></i></li>
                            </ul>
                        </div>
                    </div>                    
                </div>
            </div>
        </section>
    )
}

export default HeaderTopbar;